<template>
  <div class="h-100vh relative">
    <div
      class="bg-[#000] bg-opacity-50 fixed top-0 left-0 w-full h-full z-10"
      v-if="isWchart"
    >
      <img
        src="@/assets/images/weixin.png"
        class="w-250px ml-auto"
        alt=""
        srcset=""
      />
    </div>

    <div class="py-30px">
      <img
        class="w-200px h-200px mx-auto"
        src="@/assets/images/logo.png"
        alt=""
        srcset=""
      />
    </div>
    <h3 class="text-center text-52px font-bold my-40px">APP下载</h3>
    <!-- <div class="text-center text-gray-400 text-14px">
      更新于：2021-01-01 12:00:00
    </div> -->
    <div class="px-15px mt-60px">
      <van-button color="#378264" class="w-full bg-primary" @click="download"
        >立即下载</van-button
      >
    </div>

    <!-- 苹果 -->
    <!-- <template v-if="os == 'ios'">
      <div class="text-center mt-50px text-primary">苹果端安装步骤</div>
      <van-divider />
      <div class="text-gray-500 text-14px py-20px px-15px leading-18px">
        <p class="py-8px">
          第一步：我们点击"安装"，弹出配置下载文件时，点击"允许"。
        </p>
        <img src="@/assets/images/bz1.png" />
        <p class="py-8px">
          第二步：打开设置→通用→描述文件，即可在"已下载的描述文件"中看到，点击。
        </p>
        <img src="@/assets/images/bz2.png" />
        <p class="py-8px">
          第三步：找到右上角的"安装"，此时有可能需要输入解锁密码，再点击"安装"，即可完成。
        </p>
        <img src="@/assets/images/bz3.png" />
        <p class="py-8px">此时我们返回首页，即可看到新安装的APP。</p>
      </div>
    </template> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      bgUrl: '',
      isWchart: false,
    }
  },
  computed: {
    os() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return 'ios'
      } else {
        return 'android'
      }
    },
  },
  created() {
    this.isWeixin()
    this.ad()
    this.init()
  },
  methods: {
    isWeixin() {
      let user = navigator.userAgent.toLowerCase()
      if (user.match(/MicroMessenger/i) == 'micromessenger') {
        this.isWchart = false
      } else {
        this.isWchart = false
      }
    },
    async init() {
      const resData = await this.$api.get('/v1/app-version', {
        params: {
          cate: this.os,
        },
      })
      this.sysInfo = resData
    },
    async ad() {
      const { share_download_banner } = await this.$api.get('/v1/ads', {
        params: {
          keys: 'share_download_banner',
        },
      })
      if (share_download_banner.length)
        this.bgUrl = share_download_banner[0].image
    },
    download() {
      window.location.href = this.sysInfo.link
    },
  },
}
</script>
<style lang="scss" scoped>

.bg-img {
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.bg-form {
  background: linear-gradient(0deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

</style>
